import type { JSX } from 'react';
import { CenteredHeader as CenteredHeaderComponent } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type CenteredHeaderProps =
  SliceComponentProps<Content.CenteredHeaderSlice>;

const CenteredHeader = ({ slice }: CenteredHeaderProps): JSX.Element => (
  <CenteredHeaderComponent {...slice} />
);

export default CenteredHeader;
